import React from 'react';
import { Tooltip } from 'antd';

/**
 *
 * @interface
 * @param {React.ReactNode} children - The content that will trigger the tooltip on hover.
 * @param {string} title - The text to display in the tooltip.
 * @param {string} placement - The placement of the tooltip (e.g., 'top', 'bottom', 'left', 'right').
 */
interface ToolTipProps {
    children?: React.ReactElement;
    title?: string|React.ReactElement,
    placement?: any;
    color?: any;
}

const AppTooltip: React.FC<ToolTipProps> = ({ children, title, placement, color }) => {

    return (
        <Tooltip title={title} placement={placement || 'top'} arrow={true} color={color || ''}>
            {children}
        </Tooltip>
    )
}

export default AppTooltip;