export const GLOBAL_ENVIRONMENT = "v1/global/environment";

/**
 * @Auth
 */
export const LOGIN = "v1/auth/login";
export const FORGOT_PASSWORD = "v1/auth/forgot-password";
export const RESET_PASSWORD = "v1/auth/reset-password";
export const SETUP_PASSWORD = "v1/auth/setup-password";
export const VERIFY_2FA = "v1/auth/verify-2fa";
export const REFRESH_TOKENS_API = "v1/auth/refresh-tokens";


/**
 * @User
 */
export const CHANGE_PASSWORD: string = "v1/admins/change-password";
export const PROFILE: string = "v1/admins/profile";
export const RESEND_VERIFICATION_CODE: string = "v1/admins/resend-verification-code";
export const VERIFY_ACCOUNT: string = "v1/admins/verify-account";
export const GET_ADMINS: string = "v1/admins";
export const CHANGE_STATUS: string = "v1/admins/change-status";
export const ADD_ADMIN: string = "v1/admins/add";
export const UPDATE_ADMIN: string = "v1/admins/update";
export const GET_ADMIN_BY_ID: string = "v1/admins/getById";
export const DELETE_ADMIN_BY_ID: string = "v1/admins/delete";
export const GET_ADMINS_BY_SEARCH: string = "v1/admins/get-admins";

/**
 * @Device
 */
export const GET_DEVICES: string = "v1/devices";
export const CHANGE_DEVICE_STATUS: string = "v1/devices/change-status";
export const ADD_DEVICE: string = "v1/devices/add";
export const UPDATE_DEVICE: string = "v1/devices/update";
export const GET_DEVICE_BY_ID: string = "v1/devices/getById";
export const DELETE_DEVICE_BY_ID: string = "v1/devices/delete";
export const GET_DEVICE_BY_SEARCH: string = "v1/devices/get-devices";

/**
 * @Students
 */
export const GET_STUDENTS: string = "v1/students";
export const GET_PARENTS_OPTIONS: string = "v1/students/parents-options";

/**
 * @Fairs
 */
export const GET_FAIRS: string = "v1/fairs";
export const CHANGE_FAIR_STATUS: string = "v1/fairs/change-status";
export const ADD_FAIR: string = "v1/fairs/add";
export const UPDATE_FAIR: string = "v1/fairs/update";
export const GET_FAIR_BY_ID: string = "v1/fairs/getById";
export const GET_FAIR_WITH_INVENTORY: string = "v1/fairs/get-fair-with-inventory";
export const DELETE_FAIR_BY_ID: string = "v1/fairs/delete";
export const FAIR_UPLOAD: string = "v1/fairs/bulk-upload";
export const FAIR_OPTIONS: string = "v1/fairs/get-fair-options";
export const RESET_FAIR: string = "v1/fairs/reset";
export const SET_FAIR_CLOSE_OUT_FINISH_REVERT: string = "v1/fairs/set-close-out-finish-or-revert";
export const SCAN_MISSING_CARTONS: string = "v1/fairs/scan-missing-cartons";


/**
 * @Schools
 */
export const GET_SCHOOL_OPTIONS = "v1/schools/get-schools";


/**
 * @Roles
 */
export const GET_ROLES: string = "v1/roles";
export const ADD_ROLE: string = "v1/roles/add";
export const UPDATE_ROLE: string = "v1/roles/update";
export const GET_ROLE_BY_ID: string = "v1/roles/getById";
export const DELETE_ROLE_BY_ID: string = "v1/roles/delete";
export const GET_ROLE_BY_SEARCH: string = "v1/roles/get-master-roles";
export const GET_ROLE_MODULES_BY_SEARCH: string = "v1/roles/get-roles";
export const GET_MODULES: string = "v1/roles/get-modules-with-rights";

/**
 * @Settings
 */
export const GET_SETTINGS: string = "v1/settings";
export const UPDATE_SETTINGS: string = "v1/settings/update";
export const SYNC_SERVER: string = "v1/settings/sync";
export const UPLOAD_TEMPLATE: string = "v1/settings/templates-upload";
export const MASTER_TEMPLATE: string = "v1/settings/master-template";
export const MASTER_TEMPLATE_DOWNLOAD: string = "v1/settings/generate-template-url";

/**
 * Customized Template
 */
export const GET_CUSTOMIZED_TEMPLATE: string = "v1/customization-template";
export const SAVE_CUSTOMIZED_TEMPLATE: string = "v1/customization-template/update";

/**
 * @Inventory
 */
export const GET_FAIR_INVENTORY: string = "v1/inventory/fair-inventory";
export const CHANGE_FAIR_INVENTORY_STATUS: string = "v1/inventory/fair-inventory-change-status";
export const DELETE_FAIR_INVENTORY: string = "v1/inventory/fair-inventory-delete";
export const UPDATE_FAIR_INVENTORY: string = "v1/inventory/fair-inventory-update";
export const GET_ALL_INVENTORY: string = "v1/inventory/all-inventory";
export const CHANGE_ALL_INVENTORY_STATUS: string = "v1/inventory/all-inventory-change-status";

/**
 * @Orders
 */
export const GET_ORDERS: string = "v1/orders";
export const GET_ORDERS_BY_ID: string = "v1/orders/getById";

/**
 * @Payments
 */
export const GET_PAYMENTS: string = "v1/payments";

/**
 * @Dashboard
 */
export const GET_DASHBOARD: string = "v1/dashboard";

/**
 * @Reports
 */
export const GET_REPORTS: string = "v1/reports";